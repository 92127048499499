@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
  
}

.slick-slide > div {
  margin: 0 10px;
}
